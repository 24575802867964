<template>
  <v-card tile>
    IMAGE LIST
  </v-card>
</template>
<script>
export default {
  name: 'imagesList'
}
</script>
